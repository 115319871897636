import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subscribable, Observable, first } from 'rxjs';

import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService, UiService, UserService } from '@core/services';
import { User } from '@gqlSchema';
import { TokenRole } from '@shared/utilities/types/Role.type';

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    MatBadgeModule,
    MatIconModule,
    RouterModule,
    MatRippleModule,
  ],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
  user$: Subscribable<any>;
  company$!: Subscribable<any>;
  segment!: String;

  private router = inject(Router);
  public auth = inject(AuthService);
  private userService = inject(UserService);
  private ui = inject(UiService);

  isAuditor = false;

  @Input() showCompanyName: boolean = false;
  constructor() {
    this.user$ = this.userService.currentUser();
    this.isAuditor = this.auth.hasRole(TokenRole.Auditor);
  }
  ngOnInit(): void {}

  resendVerificationEmail() {
    (this.userService.resendVerificationEmail() as Observable<User>)
      .pipe(first())
      .subscribe({
        next: () => this.ui.snack('Verification email sent'),
        error: (err) => this.ui.snack(err.message),
      });
  }

  goToProfile() {
    this.router.navigate(['settings', 'users', this.auth.user()!.id]);
  }
}
