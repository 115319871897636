import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VALIDATION_ERROR_MESSAGES } from './validation-error-messages.token';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ValidationErrors } from '@angular/forms';
@Component({
  selector: 'app-input-error',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @for (error of errors | keyvalue; track error) {
      <mat-error>
        {{ errorsMap[error.key] }}
      </mat-error>
    }
  `,
  styles: [
  ]
})
export class InputErrorComponent {
  @Input()
  errors: ValidationErrors | undefined | null = null;

  protected errorsMap = inject(VALIDATION_ERROR_MESSAGES);
}
