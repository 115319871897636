import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  private _snackBar = inject(MatSnackBar);
  private translateService = inject(TranslateService);

  private snackBarDuration: number = 5000;
  private _loading: WritableSignal<number> = signal<number>(0);

  constructor() {}

  public enableLoader(): void {
    requestAnimationFrame(() => {
      this._loading.set(this._loading() + 1);
    });
  }

  public disableLoader(): void {
    requestAnimationFrame(() => {
      this._loading.set(this._loading() - 1);
    });
  }

  public get loading(): boolean {
    return this._loading() > 0;
  }

  public snack(
    message: string,
    action: string = 'OK',
    duration: number = this.snackBarDuration
  ) {
    message = this.translateService.instant(message);
    action = this.translateService.instant(action);
    this._snackBar.open(message, action, {
      duration: duration,
    });
  }

  public get count() {
    return this._loading();
  }
}
