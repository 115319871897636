//?Deep clon of objects
export function deepClone<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) return obj;

  if (Array.isArray(obj)) {
    const cloneArr = [] as any[];
    for (let i = 0; i < obj.length; i++) {
      cloneArr[i] = deepClone(obj[i]);
    }
    return cloneArr as T;
  }

  const cloneObj = {} as T;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      cloneObj[key] = deepClone(obj[key]);
    }
  }

  return cloneObj;
}

//read value from enum based on key
export function mapEnumFromKeyToValue(enumObj: any, enumKey: string): string {
  return enumObj[enumKey];
}

//generate search SDL query for searching objects
export function generateSearchQuery(
  columns: string[],
  query: string | null | undefined
) {
  let res: any = { or: [] };
  if (query === null || query === '') return null;

  columns.forEach((e) => {
    let props = e.split('.');
    let tmp = {};

    props.reduce((nestedObj: any, prop: any, index) => {
      if (index === props.length - 1) nestedObj[prop] = { contains: query };
      else nestedObj[prop] = {};
      return nestedObj[prop] as any;
    }, tmp);

    res.or.push(tmp);
  });

  return res;
}

//generate SDL query for filtering objects
export function generateFilterQuery(config: any): string | null {
  if (!config) return null;

  let where: any = {
    and: [],
  };

  let arr: any = Object.keys(config);

  for (let propName of arr) {
    if (isPrimitive(config[propName])) {
      if (config[propName] !== null || config[propName] !== undefined) {
        let tmp: any = {};
        tmp[propName] = { eq: config[propName] };
        where.and.push(tmp);
        continue;
      }
    } else if (isArray(config[propName])) {
      if (!config[propName]?.length) continue;
      let or: any = [];
      or[propName] = {};

      let tmpArr = {
        or: config[propName].map((status: any) => {
          let tmpArrElement: any = {};
          tmpArrElement[propName] = { eq: status };
          return tmpArrElement;
        }),
      };
      where.and.push(tmpArr);
    } else {
      if (!config[propName]) continue;
      let res = resolveObject(config[propName]);
      let key = Object.keys(res?.[0])?.[0];

      if (res?.[0]?.[key]?.eq === null) continue;

      let tmpObj: any = {};
      res.forEach((e: any) => {
        if (e.eq === null) {
          tmpObj = null;
        } else tmpObj[propName] = { ...tmpObj[propName], ...e };
      });
      where.and.push(tmpObj);
    }
  }

  if (!where.and?.length) return null;

  return where;
}

///to handle both search and filters
export function generateFilterSearchQuery(data: {
  searchValues: any;
  searchColums: string[];
}) {
  let { searchColums, searchValues } = data;
  let filterQuery;
  let searchQuery;
  let filter = { ...searchValues };
  delete filter?.search;

  if (searchValues?.search) {
    searchQuery = generateSearchQuery(searchColums, searchValues?.search);
  }

  filterQuery = generateFilterQuery(filter);

  let where: any = filterQuery;

  if (!!searchQuery) {
    if (where) where.and.push(searchQuery);
    else
      where = {
        and: [{ ...searchQuery }],
      };
  }

  return where;
}

function resolveObject(config: any) {
  const result: any = [];
  function flattenHelper(current: any, path: any[] = []) {
    for (const key in current) {
      const newPath = [...path, key];
      if (typeof current[key] === 'object' && current[key] !== null) {
        flattenHelper(current[key], newPath);
      } else {
        const flattenedObject = newPath.reduceRight(
          (acc, val) => ({ [val]: acc }),
          { eq: current[key] }
        );
        result.push(flattenedObject);
      }
    }
  }
  flattenHelper(config);
  return result;
}

function isPrimitive(param: any): boolean {
  return (
    typeof param === 'string' ||
    typeof param === 'number' ||
    typeof param === 'boolean'
  );
}

function isArray(param: any): boolean {
  return Array.isArray(param);
}
