import { Injectable, effect, inject, signal } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { Observable, finalize, first, map, tap } from 'rxjs';
// import { User } from '../graphql/schema';
import { Router } from '@angular/router';
import { User } from '@gqlSchema';
import { TokenRole } from '@shared/utilities/types/Role.type';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apollo: Apollo = inject(Apollo);
  private router: Router = inject(Router);
  private ui: UiService = inject(UiService);

  user = signal<User | null | undefined>(null);
  authToken = signal<string>('');

  public LOGIN_USER_FRAGMENT = gql`
    fragment LoginUserFragment on User {
      id
      firstName
      lastName
      authToken
      email
      emailConfirmed
      roles
      permissions
      companies {
        id
        name
        jibNumber
      }
    }
  `;

  private readonly LOGIN: DocumentNode = gql`
    mutation loginUser($request: LoginRequestInput!) {
      loginUser(request: $request) {
        ...LoginUserFragment
      }
    }
    ${this.LOGIN_USER_FRAGMENT}
  `;

  constructor() {
    let storedUser = JSON.parse(localStorage.getItem('currentUser') || 'null');
    this.user.set(storedUser);
    this.authToken.set(storedUser?.authToken);

    effect(() => {
      localStorage.setItem('currentUser', JSON.stringify(this.user()));
    });
  }

  public loginUser(data: {
    username: string;
    password: string;
  }): Observable<any | null | undefined> {
    this.ui.enableLoader();
    return this.apollo
      .mutate<User>({
        mutation: this.LOGIN,
        fetchPolicy: 'network-only',
        variables: { request: { ...data } },
      })
      .pipe(
        first(),
        finalize(() => this.ui.disableLoader()),
        map((e) => {
          return e.data;
        }),
        tap((e: any) => {
          this.user.set(e.loginUser);
          this.authToken.set(e?.loginUser?.authToken + '');
        })
      );
  }

  public async logout(): Promise<void> {
    console.log('APOLLO CACHE', this.apollo.client.cache.extract());
    this.apollo.client.clearStore().then(() => {
      this.apollo.client.cache.reset();
      this.clearStorage();
    });
    window.location.href = '/login';
  }

  public logCache(): void {
    console.log(this.apollo.client.cache.extract());
  }

  public hasRole(role: TokenRole): boolean {
    return !!this.user()?.roles?.includes(role);
  }

  public hasPermission(permission: string): boolean {
    return !!this.user()?.permissions?.includes(permission);
  }

  public clearStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
  }
}

export enum Permission {}
