import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UiService } from '@core/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  ui = inject(UiService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('Client side error!');
          errorMsg = `Error: ${error?.error?.message}`;
        } else {
          console.log('Server side error!');
          console.log(error);
          errorMsg = `${error.error?.errors?.[0]?.message}`;
        }
        console.log('msg', errorMsg);
        console.log('http error', error.message);
        this.ui.snack(error.error?.errors?.[0]?.message || error.message);
        return throwError(
          () => new Error(error.error?.errors?.[0]?.message || error.message)
        );
      })
    );
  }
}
