import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBLockActionsData } from '@shared/utilities/types/utility.types';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-info-block',
  standalone: true,
  imports: [CommonModule, RouterModule, MatButtonModule, TranslateModule],
  templateUrl: './info-block.component.html',
  styleUrl: './info-block.component.scss',
})
export class InfoBlockComponent {
  @Input()
  title: string = 'Apliciranje za administratora kompanije';

  @Input()
  description: string =
    'Da biste poslali zahtjev za kreiranje kompanije pratite sledeće korake.';

  @Input()
  actions: InfoBLockActionsData[] = [
    { name: 'Pozovi korisnike', path: '/settings/users/new' },
    {
      name: 'Zahtjev za registraciju',
      path: '/features/applications/new-company',
    },
  ];
}
