import { Injectable, inject } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { UiService } from '@core/services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  ui = inject(UiService);
  private requests: HttpRequest<any>[] = [];
  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser && currentUser.authToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.authToken}`,
        },
      });
    }

    this.ui.enableLoader();
    this.requests.push(request);

    return new Observable((observer) => {
      const subscription = next
        .handle(request)
        .pipe(finalize(() => this.ui.disableLoader()))
        .subscribe({
          next: (event) => {
            if (event instanceof HttpResponse) {
              observer.next(event);
              this.removeRequest(request);
            }
          },
          error: (err) => {
            this.removeRequest(request);
            observer.error(err);
          },
          complete: () => {
            this.removeRequest(request);
            observer.complete();
          },
        });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }
}
