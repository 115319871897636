import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicFormBuilderComponent } from '@shared/form-builder/dynamic-form-builder/dynamic-form-builder.component';
import { MatButtonModule } from '@angular/material/button';
import { StopClickPropagationDirective } from 'src/app/core/helpers/stop-click-propagation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { RegistryMenuComponent } from '@shared/components';

@Component({
  selector: 'app-select-navigation-root',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,

    MatMenuModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,

    DynamicFormBuilderComponent,
    RegistryMenuComponent,
    StopClickPropagationDirective,
  ],
  templateUrl: './select-navigation-root.component.html',
  styleUrls: ['./select-navigation-root.component.scss'],
})
export class SelectNavigationRootComponent {
  @Input({ required: true })
  set selectConfig(value: any) {
    value?.children.sort((a: any, b: any) =>
      a?.label < b?.label ? -1 : a?.label > b?.label ? 1 : 0
    );
    this.navigationConfig = value;
  }

  @Input()
  mode: 'menu' | 'select' = 'menu';

  @Input('titleDepth')
  titleDepth!: number;

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  @Output()
  onConfig: EventEmitter<RegistryNavigationSetup> = new EventEmitter();

  navigationConfig: any;

  private _rootNavigationId: string = '';
  _disabled!: boolean;
  levelTitle: string[] = [];

  getConfig(event: any) {
    if (!!this.titleDepth) {
      this.levelTitle = this.levelTitle.slice(this.titleDepth);
    }
    this.onConfig.emit({
      finalRegistryValues: event,
      rootId: this._rootNavigationId,
      finalRegistryValueId: event.id,
      selectedPathTitle: this.levelTitle.join(' → '),
    });
    this.levelTitle = [];
  }

  buildPath(event: any) {
    if (!this.levelTitle.includes(event)) this.levelTitle.push(event);
  }

  setRootNavigationId(id: string) {
    this._rootNavigationId = id;
  }

  resetTitleLvl() {
    this.levelTitle = [];
  }
}

export interface RegistryNavigationSetup {
  rootId?: string | null;
  finalRegistryValueId?: string;
  finalRegistryValues: any;
  selectedPathTitle: string;
}
