@for (item of navigationConfig?.children; track item) { @if (item.children &&
item.children.length > 0) { @if (mode==='menu') {
<button
  mat-menu-item
  [matMenuTriggerFor]="menu.childMenu"
  (click)="resetTitleLvl(); setRootNavigationId(item.id); buildPath(item.label)"
  [disabled]="_disabled"
>
  <div class="bdd-flex bdd-ai-center bdd-jc-space-between">
    <span>{{ item.label }}</span>
    <span style="font-size: 24px">&#9656;</span>
  </div>
  <!-- <mat-icon>keyboard_arrow_right</mat-icon> -->
</button>
} @if (mode==='select') {
<button
  color="accent"
  mat-stroked-button
  [matMenuTriggerFor]="menu.childMenu"
  class="border-color-accent max-width"
  [matTooltip]="navigationConfig.label"
  [disabled]="_disabled"
>
  <mat-icon>playlist_add</mat-icon>
  {{ navigationConfig.label | translate }}
</button>
}
<app-registers-menu
  (selectedPath)="buildPath($event)"
  (onConfig)="getConfig($event)"
  #menu
  [mode]="mode"
  [items]="item?.children"
/>

} @if (!item.children || item.children.length === 0) { @if (mode==='menu') {
<button
  mat-menu-item
  [disabled]="_disabled"
  (click)="setRootNavigationId(item.id); buildPath(item.label); getConfig(item)"
>
  <!-- TODO first lvl leaf -->
  <span>{{ item.label }}</span></button
>} @else if (mode==='select') {
<button
  mat-stroked-button
  [disabled]="_disabled"
  (click)="setRootNavigationId(item.id); buildPath(item.label); getConfig(item)"
>
  <!-- TODO first lvl leaf -->
  <span>{{ item.label }}</span>
</button>
}
<mat-divider></mat-divider>
} }
