<div class="item-container" [@expandCollapse]>
  <div class="toolbar">
    <button mat-icon-button (click)="goBack()" aria-label="go-back">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="item-title">
      <h3>
        <strong>{{ item?.name || this.form.value?.name | translate }}</strong>
      </h3>
      @if(!!item?.id){
      <small>{{ "Uredi" | translate }}</small
      >} @else {
      <small>{{ "Novi" | translate }}</small>
      }
    </div>
    <div class="spacer"></div>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="more-options"
      [disabled]="ui.loading || !_canManage"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      @if (!item?.deleted) {
      <button
        mat-menu-item
        color="warn"
        (click)="deleteItem()"
        [disabled]="!item?.id"
      >
        {{ "Obriši" | translate }}
      </button>
      }
      <!--@if (item?.deleted) {
       <button
        mat-menu-item
        color="warn"
        (click)="restoreItem()"
        [disabled]="false"
      >
        {{ "Restore" | translate }}
      </button> 
      }-->
    </mat-menu>
    <button
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
      [disabled]="ui.loading || !_canManage || this.form.invalid"
    >
      {{ "Sačuvaj" | translate }}
    </button>
  </div>
  <div class="holder">
    <ng-content></ng-content>
  </div>
</div>
