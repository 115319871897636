import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  effect,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialoComponent } from '@core/components';
import { ConfirmationDialog } from '@core/helpers/dialogs';
import { UiService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY, Observable, catchError, first, of, switchMap, tap } from 'rxjs';
import { expandCollapse } from 'src/app/animations/expand-collapse.animations';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-item-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,

    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
  ],
  templateUrl: './item-wrapper.component.html',
  styleUrls: ['./item-wrapper.component.scss'],
  animations: [expandCollapse],
})
export class ItemWrapperComponent implements OnInit {
  route = inject(ActivatedRoute);
  destroyRef = inject(DestroyRef);
  ui = inject(UiService);
  router = inject(Router);

  protected _canManage!: boolean;

  @Input({ required: true }) service!: BaseService;
  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) set returnPath(value: string | string[]) {
    this._returnPath = Array.isArray(value) ? [...value] : [value];
  }
  @Input() readPerm: boolean = false;
  @Input() set managePerm(value: boolean) {
    this._canManage = value;
    this.enableForm(this.item);
  }
  @Input() enableNavigation = true;
  @Input() enableSubmitHandler = false;

  @Output() itemFetched: EventEmitter<any> = new EventEmitter();
  @Output() itemCreated: EventEmitter<any> = new EventEmitter();
  @Output() onSubmitHandler: EventEmitter<void> = new EventEmitter();

  $item!: Observable<any>;
  item!: any;

  dialog = new ConfirmationDialog(ConfirmationDialoComponent, {
    title: 'Želite li obrisati element?',
    confirmAction: 'Da',
    denyAction: 'Odustani',
    description: '',
  });

  private _returnPath!: string[];

  constructor() {
    this.$item = this.route.params.pipe(
      switchMap((p) => {
        const isNew = !p['id'] || p['id'] === 'new';

        if (isNew) {
          this.form.enable();
          this.itemFetched.emit(null);
        }
        return isNew ? EMPTY : this.service.one({ id: p['id'] });
      }),
      tap((e) => {
        this.item = e;
        this.form.patchValue(e);
        this.itemFetched.emit(e);
      }),
      catchError((e) => {
        this.ui.snack(e.message);
        console.error(e);
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    );

    effect(() => {
      this.ui.loading ? this.form?.disable() : this.enableForm(this.item);
    });
  }

  ngOnInit(): void {
    this.form.disable();
    this.$item.subscribe((res) => this.enableForm(res));
  }

  onSubmit(data: any = null) {
    if (this.enableSubmitHandler) {
      this.onSubmitHandler.emit();
      return;
    }

    if (this.form.value.id) {
      this.service.modify(this.form.value).subscribe(() => {
        if (this.enableNavigation) this.goBack();
      });
    } else {
      this.service.create(this.form.value).subscribe((res) => {
        this.itemCreated.emit(res);
        if (this.enableNavigation) this.goBack();
      });
    }
  }

  deleteItem() {
    this.dialog
      .openConfirmationDialog()
      .afterClosed()
      .pipe(
        switchMap((e) => {
          if (!!e) {
            return this.service
              .delete({ id: this.item.id })
              .pipe(tap(() => this.goBack()));
          }
          return of(null);
        }),
        first()
      )
      .subscribe();
  }

  restoreItem() {
    this.service.restore({ id: this.item.id }).subscribe(() => {
      this.ui.snack('ItemIsRestored');
      this.goBack();
    });
  }

  goBack() {
    this.router.navigate(this._returnPath);
  }

  enableForm(item: any) {
    if (!this._canManage) {
      this.form?.disable();
      return;
    }

    this.form?.enable();
  }
}
