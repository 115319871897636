import { ComponentType } from '@angular/cdk/portal';
import { inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialoComponent } from '@core/components';

export class ConfirmationDialog {
  private _dialog = inject(MatDialog);
  private _dialogRef!: MatDialogRef<ConfirmationDialoComponent, boolean>;
  private _component!: ComponentType<any>;

  templateData: DialogTemplateData;

  constructor(
    component: ComponentType<any>,
    templateData?: DialogTemplateData
  ) {
    this._component = component;
    if (!templateData) {
      this.templateData = {
        title: 'Želite li obrisati element?',
        confirmAction: 'Da',
        denyAction: 'Otkaži',
        description: '',
        data: null,
      };
    } else this.templateData = templateData;
  }

  openConfirmationDialog(data: any = null) {
    this._dialogRef = this._dialog?.open(this._component, {
      data: data || this.templateData,
      maxWidth: 600,
      minWidth: 400,
      panelClass: 'cusom-dialog',
    });

    return this._dialogRef;
  }
}

export interface DialogTemplateData {
  title?: string;
  description?: string;
  confirmAction?: string;
  denyAction?: string;
  data?: any;
}
