<div
  [matMenuTriggerFor]="menu"
  class="switcher"
  matRipple
  [matRippleCentered]="true"
  role="button"
>
  <strong>
    {{ auth.user()?.firstName }} {{ auth.user()?.lastName }}
    @if (showCompanyName) {
    <small>{{ (company$ | async)?.name + "" | translate }}</small>
    }
    <small>{{ auth.user()?.email }}</small>
  </strong>
  <div
    class="avatar"
    matBadgeSize="small"
    matBadge="!"
    matBadgeColor="warn"
    [matBadgeHidden]="!auth.user() || auth.user()?.emailConfirmed"
  >
    {{ auth.user()?.firstName?.[0]}}{{ auth.user()?.lastName?.[0]}}
  </div>
  <mat-icon>expand_more</mat-icon>
</div>
<mat-menu #menu="matMenu" [xPosition]="'before'">
  @if (!auth.user()?.emailConfirmed) {
  <button mat-menu-item (click)="resendVerificationEmail()">
    <mat-icon color="warn">error</mat-icon>
    Pošalji verifikacioni email
  </button>
  }
  <button
    mat-menu-item
    (click)="goToProfile()"
    routerLinkActive="router-link-active"
  >
    <mat-icon>person</mat-icon>
    {{ "Pregled profila" | translate }}
  </button>
  @if(isAuditor){
  <button
    mat-menu-item
    routerLink="features/applications/auditor/new"
    routerLinkActive="router-link-active"
  >
    <mat-icon>edit</mat-icon>
    {{ "Uredi profil" | translate }}
  </button>
  }
  <button
    mat-menu-item
    (click)="auth.logout()"
    routerLinkActive="router-link-active"
  >
    <mat-icon>logout</mat-icon>
    {{ "Odjavi se" | translate }}
  </button>
</mat-menu>
