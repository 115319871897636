import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  OnInit,
  ViewChild,
  WritableSignal,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  MatDrawerMode,
  MatSidenav,
  MatSidenavModule,
} from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ActivatedRoute,
  Router,
  RouterModule,
  TitleStrategy,
} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, debounceTime, first, fromEvent, map } from 'rxjs';
import { RegistryMenuComponent } from 'src/app/shared/components/registry-menu/registry-menu.component';

import {
  CustomTitleStrategy,
  StopClickPropagationDirective,
} from '@core/helpers';
import { AuthService, UiService } from '@core/services';
import {
  RegistryNavigationSetup,
  SelectNavigationRootComponent,
  UserMenuComponent,
} from '@shared/components';
import { WrapperMenuItemComponent } from '@shared/components/wrapper-menu-item/wrapper-menu-item.component';
import { RegistryService } from 'src/app/features/registries/registry.service';
import { InfoBlockComponent } from '@shared/components/info-block/info-block.component';
import { TokenRole } from '@shared/utilities/types/Role.type';

@Component({
  selector: 'app-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    WrapperMenuItemComponent,
    InfoBlockComponent,

    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatDividerModule,
    MatRippleModule,
    MatProgressBarModule,
    MatTooltipModule,

    UserMenuComponent,
    RegistryMenuComponent,
    StopClickPropagationDirective,
    SelectNavigationRootComponent,
  ],
  providers: [
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
  ],
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit {
  private _cdr = inject(ChangeDetectorRef);
  auth = inject(AuthService);
  destroyRef = inject(DestroyRef);
  ui = inject(UiService);
  titleStrategy = inject(CustomTitleStrategy);
  componentRef = inject(ElementRef);
  route = inject(ActivatedRoute);
  router = inject(Router);
  registryService = inject(RegistryService);

  @ViewChild('drawer', { static: true }) sidenav!: MatSidenav;
  @ViewChild('toolbar', { static: true }) toolbar!: ElementRef<HTMLDivElement>;

  minimized: boolean = false;
  opened: boolean = true;
  isBasicUser: boolean = false;

  // ?layering sistem in navigation menu
  menuLayers!: { [key: string]: boolean };
  activeLayer: string = 'first';
  layers: { [key: string]: boolean } = {
    first: true,
    second: false,
  };
  registerConfig: any;

  menuBackdrop: boolean = true;
  menuMode: MatDrawerMode = 'side';
  appWidth!: number;
  title: WritableSignal<string> = signal('');

  resizeObservable$!: Observable<Event>;

  public constructor() {
    this.title = CustomTitleStrategy.pageTitle;
    this.isBasicUser = this.auth.hasRole(TokenRole.BasicUser);
    this.resolvePermissions();
  }

  ngOnInit(): void {
    this.registryService
      .getregistryNavigation({})
      .pipe(
        first(),
        map((e) => {
          return {
            id: null,
            name: 'root navigation',
            description: null,
            children: [...e],
          };
        })
      )
      .subscribe({
        next: (res) => {
          this.registerConfig = { ...res };
        },
        error: (err) => console.log(err.message),
      });

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeObservable$
      .pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.onResize();
      });
    this.onResize();
  }

  scrollToTop() {
    this.toolbar.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  setSubMenuOpen(layerKey: string) {
    this.layers[this.activeLayer] = false;
    this.activeLayer = layerKey;
    this.layers[layerKey] = true;
  }

  menuItemSelected(event: any) {
    if (
      this.menuMode == 'over' &&
      !(
        event.target?.hasAttribute('stay-open') ||
        event.target?.parentElement?.hasAttribute('stay-open')
      )
    )
      this.sidenav.close();
  }

  onResize() {
    if (this.appWidth == window.innerWidth) return;
    if (window.innerWidth < 960) {
      this.menuMode = 'over';
      this.menuBackdrop = true;
      this.opened = false;
    } else {
      this.menuMode = 'side';
      this.menuBackdrop = false;
      this.opened = true;
    }
    this.appWidth = window.innerWidth;
    this._cdr.markForCheck();
  }

  selectedNode(node: RegistryNavigationSetup) {
    localStorage.setItem('pageTitle', node.selectedPathTitle);
    requestAnimationFrame(() =>
      CustomTitleStrategy.pageTitle.set(node.selectedPathTitle)
    );
    this.router.navigate([
      'registries',
      node?.rootId,
      node?.finalRegistryValueId,
    ]);
  }

  //PERMISSIONS FOR FEATURES:
  permisions = new Map<string, boolean>([
    ['BuildingView', false],
    ['ElaborateView', false],
    ['CompanyView', false],
    ['UserView', false],
    ['ApplicationView', false],
    ['RegistersView', false],
    ['TranslationView', false],
    ['CityView', false],
    ['MunicipalityView', false],
    ['CatalogView', false],
    ['AuditorView', false],
  ]);

  private resolvePermissions() {
    this.permisions.forEach((v, e) => {
      this.permisions.set(e, this.auth.hasPermission(e));
    });
  }
}
