<div class="bdd-px-2">
  <h2>{{ title | translate }}</h2>
  <p>{{ description | translate }}</p>
  <p>
    {{
      "1. Ukoliko želite dodati korisnike prilikom kreiranja zahtjeva, morate prvo poslati pozivnice svim korisnicima. To možete uraditi klikom na dugme 'Pozovi korisnike'."
        | translate
    }}
    <i>
      {{
        "U ovom slučaju zahtjev za korisnike i za registraciju firme biće jedinstven"
          | translate
      }}
    </i>
  </p>
  <p>
    {{
      "2. Ukoliko ste poslali pozivnice korisnicima, možete kreirati zahtjev za registraciju kompanije. To možete učiniti klikom na dugme 'Zahtjev za registraciju'"
        | translate
    }}
  </p>

  <div class="bdd-flex bdd-gap-2 bdd-jc-center">
    @for (action of actions; track $index) {
    <button
      mat-button
      [routerLink]="action.path"
      routerLinkActive="router-link-active"
      color="primary"
    >
      {{ action.name | translate }}
    </button>
    }
  </div>

  <h2>{{ "Apliciranje za auditora" | translate }}</h2>
  <p>
    {{
      "Da biste poslali zahtjev za aktvaciju auditorskog naloga potrebno je da popunite formu za zahtjev. Ovoj formi možete pristupiti klikom na dugme  'Zahtjev za auditora'"
        | translate
    }}
  </p>

  <div class="bdd-flex bdd-gap-2 bdd-jc-center">
    <button
      mat-button
      routerLink="/features/applications/auditor/new"
      routerLinkActive="router-link-active"
      color="primary"
    >
      {{ "Zahtjev za auditora" | translate }}
    </button>
  </div>
</div>
