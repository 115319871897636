import { Injectable, inject } from '@angular/core';
import { AuthService, BaseService } from '@core/services';
import { Translation, TranslationChangeRequestInput } from '@gqlSchema';
import { TranslateService } from '@ngx-translate/core';
import { gql } from 'apollo-angular';
import { Observable, Unsubscribable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService extends BaseService<any> {
  auth = inject(AuthService);
  tt = inject(TranslateService);

  loadedTranslations = false;
  currentLanguage!: string;
  translations$!: Unsubscribable;

  override readonly selectOneFields = gql`
    fragment SelectOneFieldsTranslation on Translation {
      id
      key
      value
      languageId
      language {
        id
        name
      }
      deleted
      created
      modified
    }
  `;

  override readonly selectAllFields = gql`
    fragment SelectAllFieldsTranslationLanguage on TranslationLanguage {
      id
      key
      translations {
        languageId
        value
        language {
          id
          name
        }
      }
    }
  `;

  readonly selectByLanguageQuery = gql`
    query translationsByLanguage($isoCode2: String!) {
      translationsByLanguage(isoCode2: $isoCode2) {
        id
        key
        value
      }
    }
  `;

  readonly selectByKey = gql`
    query translationsByKey($key: String!) {
      translationsByKey(key: $key) {
        id
        language {
          id
          name
        }
        languageId
        key
        value
      }
    }
  `;

  readonly mutationCreateUpdateTranslation = gql`
    mutation createUpdateTranslations(
      $request: [TranslationChangeRequestInput!]!
    ) {
      createUpdateTranslations(request: $request) {
        id
        languageId
        key
        value
      }
    }
  `;

  constructor() {
    super();
    this.advanceFilters = true;
    this.initGql('translationLanguage');
    // this.setSortCriterum({ sortBy: 'key' });

    if (!this.loadedTranslations) {
      let lang = 'en';

      //TODO enable language
      // if (this.auth.user && this.auth.user()?.language?.isoCode2) {
      //   lang = this.auth.user()?.language?.isoCode2 || lang;
      // }

      //!IMPORTANT currently user does not have langauge included
      lang = 'sr-lt';

      this.tt.setDefaultLang(lang);
      this.tt.use(lang);
      this.getTranslations(lang);
      this.currentLanguage = lang;
    }
  }

  public getTranslationsByKey(key: string): Observable<Translation[]> {
    return this.query({
      query: this.selectByKey,
      slot: 'translatonsByKey',
      data: { key },
      useCache: false,
    }).pipe(map((e: any) => e.translationsByKey)) as unknown as Observable<
      Translation[]
    >;
  }

  public createUpdateTranslation(
    request: TranslationChangeRequestInput[]
  ): Observable<Translation> {
    this.addRefetchQuery(this.selectByLanguageQuery, {
      isoCode2: this.currentLanguage,
    });
    this.addRefetchQuery(this.selectByKey, { key: request[0].key });
    this.addRefetchQuery(this.selectAllQuery, {
      pageRequest: this.queryParams,
    });

    return this.mutation(this.mutationCreateUpdateTranslation, { request });
  }

  public getTranslations(langCode = 'sr-lt') {
    var _trans;
    try {
      _trans = JSON.parse(
        localStorage.getItem(`__translations_${langCode}`) as string
      );
    } catch (error) {
      localStorage.removeItem(`__translations_${langCode}`);
    }
    if (_trans) {
      this.fillUpTranslations(langCode, _trans);
    }
    if (!!this.translations$) {
      // this.translations$.unsubscribe();
    }
    this.translations$ = this.apollo
      .watchQuery({
        query: this.selectByLanguageQuery,
        variables: { isoCode2: langCode },
      })
      .valueChanges.pipe(
        map((result: any) => {
          if (!result.data || !result.data.translationsByLanguage) {
            return;
          }

          const translations: any = {};
          result.data.translationsByLanguage.map((tr: any) => {
            translations[tr.key] = tr.value;
          });

          localStorage.setItem(
            `__translations_${langCode}`,
            JSON.stringify(translations)
          );
          this.fillUpTranslations(langCode, translations);
        })
      )
      .subscribe(() => {});
  }

  public fillUpTranslations(langCode: string, translations: any) {
    this.tt.setTranslation(langCode, translations);

    this.tt.setDefaultLang(langCode);
    this.tt.use(langCode);
    this.loadedTranslations = true;
    this.currentLanguage = langCode;
  }
}
