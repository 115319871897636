import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

const uri = environment.apiUrl;
let i = 0;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const middleware = new ApolloLink((operation, forward) => {
    let ls = localStorage.getItem('currentUser');
    let user = JSON.parse(ls || '{}');

    if (user?.authToken) {
      operation.setContext({
        headers: new HttpHeaders().set(
          'Authorization',
          `Bearer ${user?.authToken}`
        ),
      });
    }
    return forward(operation);
  });

  const link = middleware.concat(httpLink.create({ uri }));

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          countries: {
            //to make custom cacheId signature
            // keyArgs: ['pageRequestInput', ['skip', 'take']],
            keyArgs: false,
            merge(existing, incoming) {
              return incoming?.data ? incoming?.data : existing?.data;
            },
          },
          translationLanguages: {
            keyArgs: false,
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          registryValues: {
            keyArgs: ['parentId', 'standardId'],
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          elaborates: {
            keyArgs: ['buildingId'],
            // keyArgs: false,
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          buildings: {
            keyArgs: false,
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          cities: {
            keyArgs: false,
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          cadastralMunicipalities: {
            keyArgs: false,
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          cadastralMunicipalitiesByCityId: {
            keyArgs: ['cityId'],
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          applications: {
            keyArgs: false,
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          users: {
            keyArgs: false,
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          invitationRoles: {
            keyArgs: ['isBureau'],
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          companies: {
            keyArgs: false,
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          wishCompanies: {
            keyArgs: ['where'],
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          availableStatusChanges: {
            keyArgs: ['status'],
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          availableElaborateStatuses: {
            keyArgs: ['status'],
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          catalogFormConfiguration: {
            keyArgs: ['rootId', 'id', 'valueId'],
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
          auditors: {
            keyArgs: false,
            merge(existing, incoming) {
              return incoming ? incoming : existing;
            },
          },
        },
      },
    },
  });

  if (environment.production) {
    return {
      link,
      cache,
    };
  }

  return {
    link,
    cache,
    connectToDevTools: true,
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    HttpLink,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
