import { Injectable, WritableSignal, effect, inject, signal } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateSnapshot, TitleStrategy } from "@angular/router";

@Injectable({
   providedIn: 'root'
})
export class CustomTitleStrategy extends TitleStrategy {

   private static readonly defaultTitle = 'EE RS';
   public static pageTitle = signal(CustomTitleStrategy.defaultTitle);

   private titleStrategy = inject(Title);

   constructor() {
      super();
      effect(() => {
         CustomTitleStrategy.pageTitle;
      }, { allowSignalWrites: true });
   }

   override updateTitle(snapshot: RouterStateSnapshot): void {
      const title = this.buildTitle(snapshot);
      if (title !== undefined) {
         this.setTitles(title);
      }
      else {
         this.setTitles(CustomTitleStrategy.pageTitle());
      }
   }

   private setTitles(title: string): void {
      this.titleStrategy.setTitle(title);
      CustomTitleStrategy.pageTitle.set(title)
   }
}
