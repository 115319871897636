import { gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';

//#region FRAGMENTS

//#endregion

//#region QUERIES
export const CURRENT_USER: DocumentNode = gql`
  query currentUser {
    currentUser {
      id
      email
      firstName
      lastName
      emailConfirmed
    }
  }
`;

//#endregion

//#region MUTATIONS

export const RESEND_VERIFICATION_END_USER: DocumentNode = gql`
  mutation resendEmailVerification {
    resendEmailVerification
  }
`;

export const VERIFY_EMAIL: DocumentNode = gql`
  mutation VerifyEmail($request: VerifyEmailRequestInput!) {
    verifyEmail(request: $request) {
      id
      emailConfirmed
    }
  }
`;

export const REQUEST_RESET_PASSWORD: DocumentNode = gql`
  mutation requestResetPasswordUser(
    $request: ResetPasswordRequestChangeRequestInput!
  ) {
    resetPasswordRequest(request: $request) {
      id
    }
  }
`;

export const RESET_PASSWORD: DocumentNode = gql`
  mutation ResetPasswordRequest($request: ResetPasswordChangeRequestInput!) {
    resetPassword(request: $request) {
      id
    }
  }
`;

export const REGISTER: DocumentNode = gql`
  mutation Register($request: RegisterRequestInput!) {
    register(request: $request) {
      id
      permissions
      roles
      firstName
      lastName
      email
      emailConfirmed
    }
  }
`;

export const INVITE_USER_MUTATION: DocumentNode = gql`
  mutation InviteUser($request: InviteChangeRequestInput!) {
    inviteUser(request: $request) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const ACCEPT_INVITE_USER: DocumentNode = gql`
  mutation InviteAccept($request: InviteAcceptChangeRequestInput!) {
    inviteAccept(request: $request) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const RESEND_INVITE_USER: DocumentNode = gql`
  mutation InviteResend($request: InviteResendChangeRequestInput!) {
    inviteResend(request: $request) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const LOCK_USER: DocumentNode = gql`
  mutation lockUser($userId: UUID!, $doLock: Boolean!) {
    lockUser(userId: $userId, doLock: $doLock) {
      id
      isLocked
    }
  }
`;

//#endregion
