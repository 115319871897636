import {
  ComponentRef,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { InputErrorComponent } from '@shared/components';
import { Subscription, filter, skip, startWith } from 'rxjs';

@Directive({
  selector: '[ngModel],[formControl],[formControlName]',
  standalone: true,
})
export class DynamicValidatorMessageDirective implements OnInit, OnDestroy {
  ngControl = inject(NgControl, { self: true });
  private vcr = inject(ViewContainerRef);

  private componentRef: ComponentRef<InputErrorComponent> | null = null;
  $errorMessageSub!: Subscription;

  ngOnInit(): void {
    if (!this.ngControl.control)
      throw Error(`No control model fro ${this.ngControl.name} control...`);

    this.$errorMessageSub = this.ngControl.control.statusChanges
      .pipe(
        startWith(this.ngControl.control.status),
        skip(this.ngControl instanceof NgModel ? 1 : 0),
        filter((status) => status === 'VALID' || status === 'INVALID')
      )
      .subscribe(() => {
        if (this.ngControl.errors) {
          this.componentRef ??= this.vcr.createComponent(InputErrorComponent);
          this.componentRef.setInput('errors', this.ngControl.errors);
        } else {
          this.componentRef?.destroy();
          this.componentRef = null;
        }
      });
  }
  ngOnDestroy(): void {
    this.$errorMessageSub?.unsubscribe();
  }
}
