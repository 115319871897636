<mat-menu #childMenu="matMenu" class="custom">
  @for (child of children; track child) {
  <span>
    <!-- grane -->
    @if (child.children && child.children.length > 0) {
    <span>
      <button
        mat-menu-item
        color="primary"
        [matMenuTriggerFor]="menu.childMenu"
        stop-click-propagation
        (onMenuOpen)="buildPath(child.label)"
      >
        <!-- (click)="buildPath(child.label)" -->
        @if (child.label) {
        <span style="max-width: 250px !important">{{ child.label }}</span>
        } @if (child.name && !child.label) {
        <span style="max-width: 250px !important">{{ child.name }}</span>
        }
      </button>
      <app-registers-menu
        #menu
        (selectedPath)="buildPath($event)"
        (onConfig)="getConfig($event)"
        [mode]="mode"
        [items]="child.children"
      />
    </span>
    }
    <!-- Listovi -->
    @if (!child.children || child?.children.length === 0) {
    <span>
      <button
        mat-menu-item
        (click)="buildPath(child.label); selectConfig(child)"
      >
        @if (child.label) {
        <span style="max-width: 250px !important">{{ child.label }}</span>
        } @if (child.name && !child.label) {
        <span style="max-width: 250px !important">{{ child.name }}</span>
        }
      </button>
    </span>
    <mat-divider></mat-divider>
    }
  </span>
  }
</mat-menu>
<mat-divider></mat-divider>
