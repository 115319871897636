<div class="list-container" [@expandCollapse]>
  <div class="toolbar">
    <div class="bdd-flex bdd-flex-1 bdd-flex-wrap">
      <ng-content select="[filters]"></ng-content>
    </div>
    <!-- <span class="spacer"></span> -->
    <!-- @if (showDeleted) {
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="more-options"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    } -->
    <!-- <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="getDeletedItems()">
        @if (!this._showDeleted) {
        <mat-icon>check_box_outline_blank</mat-icon>
        } @if (this._showDeleted) {
        <mat-icon color="primary">check_box</mat-icon>
        }
        {{ "Show deleted items" | translate }}
      </button>
    </mat-menu> -->
  </div>

  <ng-content></ng-content>

  @if (!!service && service.totalCount > 0) {
  <mat-paginator
    [length]="service.totalCount"
    [pageSize]="service.queryParams.limit"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="service.applyPager($event.pageIndex, $event.pageSize)"
    [pageIndex]="service.pageIndex"
  >
  </mat-paginator>
  } @else { @if (!ui.loading) {
  <empty-list></empty-list>
  } } @if (managePerm && !hideNewButoon) {
  <button
    color="primary"
    class="fab-fixed"
    mat-fab
    aria-label="Add new"
    (click)="addNewItem()"
  >
    <mat-icon>add</mat-icon>
  </button>
  }
</div>
