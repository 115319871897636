<mat-form-field>
  <mat-label>{{ "Search" | translate }}</mat-label>
  <button *ngIf="query" mat-icon-button matSuffix (click)="clearSearchValue()">
    <mat-icon>clear</mat-icon>
  </button>
  <mat-icon *ngIf="!query" matSuffix>search</mat-icon>
  <input
    matInput
    [placeholder]="'Type to search...' | translate"
    [(ngModel)]="query"
    (input)="onInputChange(query)"
  />
</mat-form-field>
