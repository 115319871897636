<mat-drawer-container
  #drawerConainer
  class="container"
  [hasBackdrop]="menuBackdrop"
>
  <mat-drawer
    #drawer
    class="sidenav"
    [mode]="menuMode"
    [opened]="opened"
    [ngClass]="{ minimized: minimized }"
    (click)="menuItemSelected($event)"
  >
    @if (!minimized) {
    <div class="bdd-flex bdd-ai-center">
      <img srcset="/assets/images/logo.png" class="logo" alt="logo" />
      <span class="fond-logo">EKO FOND RS</span>
    </div>
    }

    <div class="text-center">
      @if (minimized) {
      <img src="/assets/images/logo.png" class="logo-mini" alt="logo" />
      }
    </div>

    <mat-divider></mat-divider>

    <div style="position: relative; height: 100%" class="overflow-y-auto">
      <div
        [ngClass]="{
          'first-level': true,
          open: layers['first']
        }"
        role="menubar"
      >
        @if (permisions.get('RegistersView')) {
        <button
          mat-menu-item
          stay-open
          (click)="setSubMenuOpen('third')"
          role="menuitem"
        >
          <mat-icon>view_list</mat-icon>
          @if (!minimized) {
          <span stay-open>{{ "Registri" | translate }}</span>
          }
        </button>
        <mat-divider />
        } @if (permisions.get('BuildingView')) {
        <app-wrapper-menu-item
          itemLabel="Objekti"
          icon="business"
          navigationUrl="/features/buildings"
          [menuMinimized]="minimized"
        />} @if (permisions.get('ApplicationView')) {
        <app-wrapper-menu-item
          itemLabel="Zahtjevi"
          icon="file_copy"
          navigationUrl="/features/applications"
          [menuMinimized]="minimized"
        />} @if (permisions.get('CompanyView')) {
        <app-wrapper-menu-item
          itemLabel="Kompanije"
          icon="business_center"
          navigationUrl="/features/companies"
          [menuMinimized]="minimized"
        />
        } @if (permisions.get('ElaborateView')) {
        <app-wrapper-menu-item
          itemLabel="Elaborati"
          icon="chrome_reader_mode"
          navigationUrl="/features/elaborates"
          [menuMinimized]="minimized"
        />} @if (permisions.get('CatalogView')) {
        <app-wrapper-menu-item
          itemLabel="Katalog"
          icon="view_list"
          navigationUrl="/features/catalogs"
          [menuMinimized]="minimized"
        />}@if (permisions.get('AuditorView')) {
        <app-wrapper-menu-item
          itemLabel="Auditori"
          icon="people"
          navigationUrl="/features/auditors"
          [menuMinimized]="minimized"
        />}
      </div>
      <div
        [ngClass]="{
          'second-level': true,
          open: layers['second']
        }"
      >
        <button
          mat-menu-item
          stay-open
          (click)="setSubMenuOpen('first')"
          role="menuitem"
        >
          <mat-icon>chevron_left</mat-icon>
          @if (!minimized) {
          <span stay-open>{{ "Nazad" | translate }}</span>
          }
        </button>
        <mat-divider />
        @if (permisions.get('TranslationView')) {
        <app-wrapper-menu-item
          itemLabel="Prevodi"
          icon="translate"
          navigationUrl="/settings/translations"
          [menuMinimized]="minimized"
        />} @if (permisions.get('CityView')) {
        <app-wrapper-menu-item
          itemLabel="Gradovi"
          icon="flag"
          navigationUrl="/settings/cities"
          [menuMinimized]="minimized"
        />} @if (permisions.get('MunicipalityView')) {
        <app-wrapper-menu-item
          itemLabel="Katastarske opštine"
          icon="landscape"
          navigationUrl="/settings/cadastral-municipalities"
          [menuMinimized]="minimized"
        />} @if (permisions.get('UserView')) {
        <app-wrapper-menu-item
          itemLabel="Korisnici"
          icon="people"
          navigationUrl="/settings/users"
          [menuMinimized]="minimized"
        />}
      </div>

      <!-- 3rd layer -->
      <div
        [ngClass]="{
          'second-level': true,
          open: layers['third']
        }"
      >
        <button
          mat-menu-item
          stay-open
          (click)="setSubMenuOpen('first')"
          role="menuitem"
        >
          <mat-icon>chevron_left</mat-icon>
          @if (!minimized) {
          <span stay-open>{{ "Nazad" | translate }}</span>
          }
        </button>
        <mat-divider></mat-divider>

        <!-- !dynamic menu -->
        @if (!!registerConfig) {
        <app-select-navigation-root
          [titleDepth]="-2"
          stay-open
          [selectConfig]="registerConfig"
          [mode]="'menu'"
          (onConfig)="selectedNode($event)"
        />
        }
      </div>
    </div>
    <!-- <div class="spacer"></div> -->
    <div class="settings" [ngClass]="{ col: minimized }">
      @if (minimized) {
      <button
        mat-menu-item
        stay-open
        (click)="minimized = !minimized; drawerConainer.open()"
      >
        @if (!minimized) {
        <mat-icon>chevron_left</mat-icon>
        } @if (minimized) {
        <mat-icon>chevron_right</mat-icon>
        }
      </button>
      }

      <button
        mat-menu-item
        stay-open
        (click)="setSubMenuOpen('second')"
        aria-label="settings-btn"
        role="menuitem"
      >
        <mat-icon>settings</mat-icon>
        @if (!minimized) {
        <span stay-open>
          {{ "Podešavanja" | translate }}
        </span>
        }
      </button>
      @if (!minimized) {
      <button
        stay-open
        mat-icon-button
        class="minimise-btn"
        (click)="this.minimized = !this.minimized; drawerConainer.open()"
        aria-label="back-btn"
        role="menuitem"
      >
        @if (!minimized) {
        <mat-icon>chevron_left</mat-icon>
        }
      </button>
      }
    </div>
  </mat-drawer>

  <div #toolbar>
    <mat-toolbar>
      <mat-toolbar-row>
        <button
          mat-icon-button
          type="button"
          (click)="drawer.toggle()"
          aria-label="open-close-menu"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <h2 [matTooltip]="title() | translate">{{ title() | translate }}</h2>
        <span class="spacer"></span>
        <app-user-menu />
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-divider></mat-divider>
    @if (ui.loading) {
    <mat-progress-bar color="primary" mode="query"></mat-progress-bar>
    }
  </div>

  <div class="sidenav-content">
    @if (isBasicUser) {
    <app-info-block />
    }
    <router-outlet (activate)="scrollToTop()"></router-outlet>
  </div>
</mat-drawer-container>
