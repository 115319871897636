import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-wrapper-menu-item',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,

    MatDividerModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './wrapper-menu-item.component.html',
  styleUrl: './wrapper-menu-item.component.scss',
})
export class WrapperMenuItemComponent {
  @Input({ required: true })
  navigationUrl!: string;

  @Input({ required: true })
  menuMinimized!: boolean;

  @Input({ required: true })
  icon!: string;

  @Input({ required: true })
  itemLabel!: string;
}
