import { Injectable, inject, signal } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { EMPTY, Observable, catchError, first, map, of, tap } from 'rxjs';
import { UiService } from '@core/services';
import { PageRequestInput } from '@gqlSchema';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BaseService<ReturnT = any, ImportT = any> {
  apollo: Apollo = inject(Apollo);
  ui: UiService = inject(UiService);
  translate = inject(TranslateService);

  advanceFilters: boolean = false;

  //This object is use to store QueryRef-s of the initialized queries for fetching object by id
  public oneWQ: Map<string, QueryRef<ReturnT, any>> = new Map();

  //This object is use to store QueryRef-s of the initialized queries for fetching lists of objects
  public allWQ: Map<string, QueryRef<ReturnT, any>> = new Map();

  //This object is use to store QueryRef-s of the initialized custom queries.
  public queriesWQ: Map<string, QueryRef<ReturnT, any>> = new Map();

  //for filtering
  public where: any;

  public pageIndex: number = 0;
  public totalCount: number = 0;

  protected selectOneFields!: DocumentNode;
  protected selectAllFields!: DocumentNode;
  protected selectOneQuery!: DocumentNode;
  protected selectAllQuery!: DocumentNode;
  protected createMutation!: DocumentNode;
  protected modifyMutation!: DocumentNode;
  protected deleteMutation!: DocumentNode;
  protected restoreMutation!: DocumentNode;
  protected publishMutation!: DocumentNode;
  protected refetchAdditionalQueries: any = [];

  private nodeName!: string;
  private nodeNamePlural!: string;

  private modifiedMsg = 'ItemModified';
  private createdMsg = 'ItemCreated';
  private deletedMsg = 'ItemDeleted';
  private restoredMsg = 'ItemRestored';

  /**
   * @description An object for handling query params
   */
  public queryParams: PageRequestInput = {
    limit: 25,
    offset: 0,
  };

  constructor() {
    this.modifiedMsg = this.translate.instant('ItemModified');
    this.createdMsg = this.translate.instant('ItemCreated');
    this.deletedMsg = this.translate.instant('ItemDeleted');
    this.restoredMsg = this.translate.instant('ItemRestored');
  }

  /**
   * @function  initGql
   * @argument nodeName is nameof the query/mutation  document node
   * @description This function will create all necessary query/mutation document nodes
   */
  public initGql(nodeName: string, nodeNamePlural: string | null = null) {
    this.nodeName = nodeName;
    this.nodeNamePlural = nodeNamePlural ? nodeNamePlural : `${nodeName}s`;
    this.createQueries();
  }

  private createQueries() {
    this.selectOneQuery = gql`
    query ${this.nodeName}($id: UUID!) {
      ${this.nodeName}(id: $id) {
        ...SelectOneFields${this.camelNodeName()}
      }
    }
    ${this.selectOneFields}
    `;

    let serviceName = this.camelNodeName();
    let whereVars = '';
    let whereVal = '';
    let itemsName = 'data';
    if (this.advanceFilters) {
      whereVars = `$where: ${serviceName}FilterInput`;
      whereVal = 'where:$where';
      itemsName = itemsName + ':items';
    }
    this.selectAllQuery = gql`
      query ${this.nodeNamePlural}($skip: Int, $take: Int, ${whereVars}) {
        ${this.nodeNamePlural}(skip:$skip take:$take ${whereVal}) {
          ${itemsName} {
            ...SelectAllFields${this.camelNodeName()}
          }
          totalCount
        }
      }
      ${this.selectAllFields}
      `;

    this.createMutation = gql`
    mutation add${this.camelNodeName()}($request: ${this.camelNodeName()}ChangeRequestInput!) {
      create${this.camelNodeName()}(request: $request)
      {
        ...SelectOneFields${this.camelNodeName()}
      }
    }
    ${this.selectOneFields}
    `;

    this.modifyMutation = gql`
    mutation edit${this.camelNodeName()}($request: ${this.camelNodeName()}ChangeRequestInput! ) {
      edit${this.camelNodeName()}(request: $request)
      {
        ...SelectOneFields${this.camelNodeName()}
      }
    }
    ${this.selectOneFields}
    `;

    this.deleteMutation = gql`
    mutation remove${this.camelNodeName()}($id: UUID!) {
      remove${this.camelNodeName()}(id: $id)
      {
        id
        deleted
      }
    }
    `;

    this.restoreMutation = gql`
    mutation restore${this.camelNodeName()}($id: UUID!) {
      restore${this.camelNodeName()}(id: $id)
      {
        ...SelectOneFields${this.camelNodeName()}
      }
    }
    ${this.selectOneFields}
    `;

    this.publishMutation = gql`
    mutation publish${this.camelNodeName()}($id: UUID!) {
      publish${this.camelNodeName()}(id: $id)
      {
        ...SelectOneFields${this.camelNodeName()}
      }
    }
    ${this.selectOneFields}
    `;
  }

  /**
   * @description This method will create request to the backed and save the queryRef for manage state of the query.
   * If the query is already initialized the value will be returned from the cache
   * @param data.id of corresponding object that we want to get
   * @param data.slot name of the query where the queryRef will be stored. If the slot name is not perovided, the queryRef will be nammed by using id value
   * @param data.useCache Option to user cache or network only.
   * @returns An observable<ReturnT> with the value of wanted object
   */
  public one(data: {
    id: string;
    slot?: string | null;
    useCache?: boolean;
  }): Observable<ReturnT> {
    let { id, slot = id, useCache = true } = data;

    slot = slot || id;
    if (!this.oneWQ.has(slot)) {
      console.log(
        'Main subscriber  created for ONE ' + this.nodeName + ' ' + id
      );
      this.oneWQ.set(
        slot,
        this.apollo.watchQuery<ReturnT, any>({
          query: this.selectOneQuery,
          variables: { id },
          fetchPolicy: useCache ? 'cache-first' : 'network-only',
        })
      );
    }
    return this.oneWQ.get(slot)!.valueChanges.pipe(
      map<any, any>((result: any) => {
        if (!result || !result.data) return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }
        return null;
      }),
      catchError((e) => {
        this.ui.snack(e.message);
        return EMPTY;
      })
    );
  }

  builCustomQuery(nodeName: string) {
    return gql`
    query ${nodeName}($pageRequest: PageRequestInput) {
      ${nodeName}:${this.nodeNamePlural}(pageRequest: $pageRequest) {
        data {
          ...SelectAllFields
          created
        }
        totalCount
      }
    }
    ${this.selectAllFields}
    `;
  }

  /**
   * @description This method will create request to the backed and save the queryRef for manage state of the query.
   * If the query is already initialized the value will be returned from the cache
   * @param options.slot name of the query where the queryRef will be stored. If the slot name is not perovided, the queryRef will be nammed by using nodeNamePlurar
   * @param options.useCache Option to user cache or network only.
   * @returns An observable<ReturnT[]> with the value of wanted list of objects
   */

  public all(
    options: {
      slot?: string;
      pageRequest?: PageRequestInput;
      where?: any;
      useCache?: boolean;
    } = {}
  ): Observable<ReturnT[]> {
    let {
      slot = this.nodeNamePlural,
      pageRequest = this.queryParams,
      useCache = true,
      where = this.where,
    } = options;
    let query =
      slot === this.nodeNamePlural
        ? this.selectAllQuery
        : this.builCustomQuery(slot);

    if (!this.allWQ.has(slot)) {
      console.log('new query created ' + slot);
      let variables = { skip: pageRequest.offset, take: pageRequest.limit };
      if (this.advanceFilters) {
        variables = { ...variables, ...where };
      }
      const queryRef = this.apollo.watchQuery<ReturnT>({
        query: query,
        fetchPolicy: useCache ? 'cache-first' : 'network-only',
        variables: { ...variables },
      });
      this.allWQ.set(slot, queryRef);
      this.addRefetchQuery(this.selectAllQuery, {
        variables: { ...variables },
      });
    }
    return this.allWQ.get(slot)!.valueChanges.pipe(
      map((result: any) => {
        if (!result || !result.data) return null;

        const keys = Object.keys(result.data);

        if (result.data && keys.length) {
          this.totalCount = result.data[keys[0]].totalCount || 0;
          return result.data[keys[0]].data;
        }
        return of(null);
      }),
      catchError((error) => {
        console.log('ERROR on baseService.all()', error);
        this.ui.snack(error.message);
        return of([]);
      })
    );
  }

  /**
   * @description Create an api request to create new object
   * @param data the value that will be provided as a variables in the request
   * @returns the observable of the created object
   */
  public create(data: any): Observable<ReturnT> {
    this.ui.enableLoader();
    return this.apollo
      .mutate<ImportT>({
        mutation: this.createMutation,
        refetchQueries: [
          {
            query: this.selectAllQuery,
            variables: { pageRequest: this.queryParams },
          },
          ...this.refetchAdditionalQueries,
        ],
        variables: { request: data },
      })
      .pipe(
        first(),
        map((result: any) => {
          if (!result || !result.data) return null;

          const keys = Object.keys(result.data);
          if (result.data && keys.length) {
            return result.data[keys[0]];
          }
          return null;
        }),
        tap(() => {
          this.ui.snack(this.createdMsg);
          this.ui.disableLoader();
        }),
        catchError((e) => {
          this.ui.snack(e.message);
          this.ui.disableLoader();
          return EMPTY;
        })
      );
  }

  /**
   * @description Create an api request to modify an existing object
   * @param data the value that will be provided as a variables in the request
   * @returns the observable of the modified object
   */
  public modify(data: ImportT): Observable<ReturnT> {
    this.ui.enableLoader();
    return this.apollo
      .mutate({
        mutation: this.modifyMutation,
        refetchQueries: [...this.refetchAdditionalQueries],
        variables: { request: data },
      })
      .pipe(
        first(),
        map((result: any) => {
          if (!result || !result.data) return null;

          const keys = Object.keys(result.data);
          if (result.data && keys.length) {
            return result.data[keys[0]];
          }
          return null;
        }),
        tap(() => {
          this.ui.snack(this.modifiedMsg);
          this.ui.disableLoader();
        }),
        catchError((e) => {
          this.ui.snack(e.message);
          this.ui.disableLoader();
          return EMPTY;
        })
      );
  }

  /**
   * @description Create an api request to delete an existing object
   * @param data the value that will be provided as a variables in the request
   * @example data:{id:'someValue'}
   * @returns the observable of the deleted object
   */
  public delete(data: any): Observable<ReturnT> {
    this.ui.enableLoader();
    return this.apollo
      .mutate({
        mutation: this.deleteMutation,
        refetchQueries: [...this.refetchAdditionalQueries],
        variables: { id: data.id },
      })
      .pipe(
        first(),
        map((result: any) => {
          if (!result || !result.data) return null;

          const keys = Object.keys(result.data);
          if (result.data && keys.length) {
            return result.data[keys[0]];
          }

          return null;
        }),
        tap(() => {
          this.ui.snack(this.deletedMsg);
          this.ui.disableLoader();
        }),
        catchError((e) => {
          this.ui.snack(e.message);
          this.ui.disableLoader();
          return EMPTY;
        })
      );
  }

  /**
   * @description Create an api request to restore a deleted object
   * @param data the value that will be provided as a variables in the request
   * @example data:{id:'someValue'}
   * @returns the observable of the restored object
   */
  public restore(data: any) {
    this.ui.enableLoader();
    return this.apollo
      .mutate({
        mutation: this.restoreMutation,
        refetchQueries: [
          {
            query: this.selectAllQuery,
            variables: { pageRequest: this.queryParams },
          },
          { query: this.selectOneQuery, variables: { id: data.id } },
          ...this.refetchAdditionalQueries,
        ],
        variables: { id: data.id },
      })
      .pipe(
        first(),
        tap(() => {
          this.ui.snack(this.restoredMsg);
          this.ui.disableLoader();
        }),
        catchError((e) => {
          this.ui.snack(e.message);
          this.ui.disableLoader();
          return EMPTY;
        })
      );
  }

  /**
   * @description This method will create request to the backed and save the queryRef for manage state of the query. Use this method to create custom queries.
   * If the query is already initialized the value will be returned from the cache
   * @param options.query Document node name of the custom query
   * @param options.slot name of the query where the queryRef will be stored. If the slot name is not perovided, the queryRef will be nammed by using nodeNamePlurar
   * @param options.useCache Option to user cache or network only.
   * @returns An observable<ReturnT[]> with the value of wanted list of objects
   * @param options.data the value that will be provided as a variables in the request
   */
  // protected query(query: DocumentNode, slot: string, data: any = null, useCache = true): Observable<ReturnT> {
  protected query<T>(options: {
    query: DocumentNode;
    slot: string;
    data?: any;
    useCache?: boolean;
  }): Observable<T> {
    let { query, slot, data = null, useCache = true } = options;
    if (!this.queriesWQ.has(slot)) {
      console.log('New query created... ' + slot);
      this.queriesWQ.set(
        slot,
        this.apollo.watchQuery({
          query: query,
          fetchPolicy: useCache ? 'cache-first' : 'network-only',
          variables: data,
        })
      );
    }
    return this.queriesWQ.get(slot)!.valueChanges.pipe(
      map(
        (result: any) => {
          if (!result || !result.data) return null;

          const keys = Object.keys(result.data);
          if (result.data && keys.length) {
            return result.data;
          }

          return null;
        },
        catchError((error) => {
          this.ui.snack(error.message);
          return of(null);
        })
      )
    );
  }

  /**
   * @description This method will create POST request to the backed and save the queryRef for manage state of the query. Use this method to create custom mutations.
   * If the query is already initialized the value will be returned from the cache
   * @param mutation Document node name of the custom mutation
   * @returns An observable<ReturnT[]> with the value of wanted list of objects
   * @param data the value that will be provided as a variables in the request
   */
  protected mutation(
    mutation: DocumentNode,
    data: any,
    update: any = null
  ): Observable<ReturnT> {
    return this.apollo
      .mutate({
        mutation: mutation,
        refetchQueries: this.refetchAdditionalQueries,
        variables: data,
        update: update,
      })
      .pipe(
        first(),
        map((result: any) => {
          if (!result || !result.data) return null;

          const keys = Object.keys(result.data);
          if (result.data && keys.length) {
            return result.data[keys[0]];
          }
          return null;
        }),
        catchError((error) => {
          this.ui.snack(error.message);
          return EMPTY;
        })
      );
  }

  /**
   * @description use this method to fetchMore data from backen in situations like pagination refetch read more on @link https://www.apollographql.com/docs/react/pagination/core-api/
   * @param slot name of the queryRef which is stored in the store
   */
  public fetchMoreData(
    slot: string | any = null,
    pageRequest: PageRequestInput = this.queryParams,
    where: any = this.where
  ): void {
    slot = slot || this.nodeNamePlural;
    let variables: any = { skip: pageRequest.offset, take: pageRequest.limit };
    if (this.advanceFilters) {
      variables = { ...variables, ...{ where } };
    }
    if (this.allWQ.has(slot)) {
      this.allWQ.get(slot)!.fetchMore({
        variables: variables,
      });
    } else
      console.error(
        "Query '" +
          slot +
          "' in '" +
          this.nodeName +
          "' service is not initialized: STORE 'allWQ'"
      );
  }

  /**
   * @description use this method to refetch a custom query
   * @param slot name of the queryRef which is stored in queriesWQ store
   * @param data data for refetch
   * @link https://the-guild.dev/graphql/apollo-angular/docs/data/queries
   */
  public refetchQuery(
    slot: string,
    data: any = {},
    pageRequest: PageRequestInput = this.queryParams
  ) {
    let variables = { ...data } || { ...pageRequest };
    if (!this.queriesWQ.has(slot)) {
      console.error(
        "Query '" +
          slot +
          "' in '" +
          this.nodeName +
          "' service is not initialized: STORE 'queriesWQ'"
      );
      return;
    }

    this.queriesWQ.get(slot)!.refetch(variables);
  }

  /**
   * @description use this method to refetch list of objects
   * @param slot name of the queryRef which is stored in queriesWQ store
   * @param data data for refetch
   * @link https://the-guild.dev/graphql/apollo-angular/docs/data/queries
   */
  public refetchAll(
    slot: string = this.nodeNamePlural,
    data: any = this.queryParams
  ) {
    if (!this.allWQ.has(slot)) {
      console.error(
        "Query '" +
          slot +
          "' in " +
          this.nodeName +
          "' service is not initialized: STORE 'allWQ'"
      );
      return;
    }
    this.allWQ.get(slot)!.refetch({ ...data });
  }

  /**
   * @description use this method to refetch one object
   * @param slot name of the queryRef which is stored in queriesWQ store
   * @param data data for refetch
   * @link https://the-guild.dev/graphql/apollo-angular/docs/data/queries
   */
  public refetchOne(slot: string | null = null, data: any = null) {
    if (this.oneWQ.size < 1) {
      console.error(
        'There is no any cached values for one object in ' +
          this.nodeName +
          ' service ' +
          this.nodeName +
          " :STORE 'oneWQ'"
      );
      return;
    }
    //get first stored id by default
    slot = slot || this.oneWQ.entries().next().value[0];
    data = data || { id: this.oneWQ.entries().next().value[0] };
    if (!this.oneWQ.get(slot!)) {
      console.error(
        "Query '" +
          slot +
          "' in '" +
          this.nodeName +
          "' service is not initialized"
      );
      return;
    }

    this.oneWQ.get(slot!)!.refetch(data);
  }

  /**
   * @description use this method to add queries that need to be refetched after some operation
   * @param query document name of the query
   * @param variables variables for refetch
   */
  public addRefetchQuery(query: DocumentNode, variables: any = null): void {
    this.refetchAdditionalQueries.push({ query, variables });
  }

  /**
   * @description use this method to set sort criteria
   * @param sortBy Sort by some criterum, by default is 'Name'
   * @param sortOrder Sort order, it can be eather 'ASC' or 'DESC', by default is 'ASC'
   */
  // public setSortCriterum({ sortBy = 'Name', sortOrder = 'ASC' }: { sortBy?: string; sortOrder?: 'ASC' | 'DESC' } = {}): void {
  // this.queryParams.sortBy = sortBy;
  // this.queryParams.sortOrder = sortOrder;
  // }
  public applyPager(pageIndex: number, pageSize: number) {
    this.pageIndex = pageIndex;
    this.queryParams.limit = pageSize;
    this.queryParams.offset = pageIndex * pageSize;
    this.fetchMoreData();
  }

  public showDeleted(show: boolean = true) {
    // this.queryParams.showDeleted = show;
    // this.queryParams.skip = 0;
    this.fetchMoreData();
  }

  private camelNodeName() {
    if (!this.nodeName || this.nodeName.length == 0) return '';

    return this.nodeName.charAt(0).toUpperCase() + this.nodeName.slice(1);
  }

  public identify(index: number, item: any) {
    return item.id;
  }

  public printCache() {
    console.log(this.apollo.client.cache);
  }

  public setPaginationsToAll(offset: number = 0, limit: number = 9_999) {
    this.queryParams.offset = offset;
    this.queryParams.limit = limit;
  }

  public resetPagination(offset: number = 0, limit: number = 25) {
    this.queryParams.offset = offset;
    this.queryParams.limit = limit;
  }
}
