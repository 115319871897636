import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, Router } from '@angular/router';
import { StopClickPropagationDirective } from '@core/helpers';

@Component({
  selector: 'app-sidebar-wrapper',
  standalone: true,
  imports: [
    CommonModule,

    MatToolbarModule,
    MatIconModule,
    MatButtonModule,

    StopClickPropagationDirective,
  ],
  templateUrl: './sidebar-wrapper.component.html',
  styleUrls: ['./sidebar-wrapper.component.scss'],

  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class SidebarWrapperComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);

  sidebarIsVisible = true;

  closeSidebar() {
    this.sidebarIsVisible = false;
  }

  animationStateChange(event: any) {
    if (event.toState === 'void') {
      this.router.navigate([{ outlets: { sidebar: null } }], {
        relativeTo: this.route.parent,
      });
    }
  }
}
