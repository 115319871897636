import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'empty-list',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule],
  templateUrl: './empty-list-placeholder.component.html',
  styleUrls: ['./empty-list-placeholder.component.scss'],
})
export class EmptyListPlaceholderComponent {
  @Input()
  title = 'Lista je prazna';

  @Input()
  description = 'Dodajte novi unos klikom na plus dugme';

  @Input()
  icon = 'list_alt';
}
