import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UiService } from '@core/services';

@Component({
  selector: 'app-public-wrapper',
  standalone: true,
  imports: [CommonModule, RouterModule, MatProgressSpinnerModule],
  templateUrl: './public-wrapper.component.html',
  styleUrls: ['./public-wrapper.component.scss'],
})
export class PublicWrapperComponent {
  ui = inject(UiService);
  @Input('mini') mini: boolean = false;
}
