import { Injectable, inject } from '@angular/core';
import { Apollo, QueryRef, TypedDocumentNode, gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { EMPTY, Observable, catchError, first, map, tap } from 'rxjs';
import { FormControl, RegistryChangeRequestInput } from '@gqlSchema';
import { UiService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class RegistryService {
  apollo: Apollo = inject(Apollo);
  ui: UiService = inject(UiService);

  private formConfigRefs: Map<string, QueryRef<FormControl, any>> = new Map();
  private registryNavigationRefs: Map<string, QueryRef<FormControl, any>> =
    new Map();
  private registryListRefs: Map<string, QueryRef<FormControl, any>> = new Map();
  private _addRefetchQueries: {
    query: TypedDocumentNode<any, any>;
    variables: any;
  }[] = [];

  //#region REGISTRY CONFIG

  private readonly selectRegistryControlFragment: DocumentNode = gql`
    fragment RegistryControlFragmen on FormControl {
      name
      label
      controlType
      type
      value
      hidden
      order
      validators {
        max
        min
        required
        pattern
      }
      options {
        label
        value
      }
    }
  `;

  private readonly registryValueFormConfigFragment = gql`
    fragment RegistryValueFormConfigFragment on FormControl {
      ...RegistryControlFragmen
      controls {
        ...RegistryControlFragmen
        controls {
          ...RegistryControlFragmen
          controls {
            ...RegistryControlFragmen
          }
          children {
            ...RegistryControlFragmen
          }
        }
        children {
          ...RegistryControlFragmen
          controls {
            ...RegistryControlFragmen
          }
          children {
            ...RegistryControlFragmen
          }
        }
      }
      children {
        ...RegistryControlFragmen
        controls {
          ...RegistryControlFragmen
          controls {
            ...RegistryControlFragmen
          }
          children {
            ...RegistryControlFragmen
          }
        }
        children {
          ...RegistryControlFragmen
          controls {
            ...RegistryControlFragmen
          }
          children {
            ...RegistryControlFragmen
          }
        }
      }
    }
    ${this.selectRegistryControlFragment}
  `;

  private readonly registryValueFormQuery: DocumentNode = gql`
    query RegistryValueForm($rootId: UUID!, $valueId: UUID) {
      registryValueForm(rootId: $rootId, valueId: $valueId) {
        ...RegistryValueFormConfigFragment
      }
    }
    ${this.registryValueFormConfigFragment}
  `;

  public getRegistryItemFormConfig(data: {
    rootId: string;
    valueId?: string | null;
    cacheKey?: string;
    useCache?: boolean;
  }) {
    let { rootId, valueId = null, cacheKey = rootId, useCache = true } = data;
    this.ui.enableLoader();
    if (!!valueId) cacheKey = rootId + valueId;

    if (!this.formConfigRefs.has(cacheKey)) {
      console.log('Main subscriber created ref for key ' + cacheKey);
      this.formConfigRefs.set(
        cacheKey,
        this.apollo.watchQuery<FormControl, any>({
          query: this.registryValueFormQuery,
          variables: { rootId, valueId },
          fetchPolicy: useCache ? 'cache-first' : 'network-only',
        })
      );
    }
    return this.formConfigRefs.get(cacheKey)!.valueChanges.pipe(
      map<any, any>((result: any) => {
        this.ui.disableLoader();
        if (!result || !result.data) return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }
        return null;
      }),
      catchError((e) => {
        console.log(e);
        this.ui.disableLoader();
        return EMPTY;
      })
    );
  }
  // #endregion

  //#region REGISTRY NAVIGATION
  private readonly registryNavigationItemFragment = gql`
    fragment RegistryNavigation on RegistryNavigationDto {
      id
      label: name
      description
    }
  `;

  private readonly registryNavigationFragment = gql`
    query RegistryNavigation($standardId: UUID) {
      registryNavigation(standardId: $standardId) {
        ...RegistryNavigation
        children {
          ...RegistryNavigation
          children {
            ...RegistryNavigation
            children {
              ...RegistryNavigation
              children {
                ...RegistryNavigation
                children {
                  ...RegistryNavigation
                }
              }
            }
          }
        }
      }
    }
    ${this.registryNavigationItemFragment}
  `;

  public getregistryNavigation(data: {
    standardId?: string;
    cacheKey?: string;
    useCache?: boolean;
  }) {
    let { standardId, cacheKey = standardId || 'root', useCache = true } = data;
    this.ui.enableLoader();
    let ref = this.apollo.watchQuery<FormControl, any>({
      query: this.registryNavigationFragment,
      variables: { standardId },
      fetchPolicy: useCache ? 'cache-first' : 'network-only',
    });
    return ref!.valueChanges.pipe(
      map<any, any>((result: any) => {
        this.ui.disableLoader();
        if (!result || !result.data) return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }
        return null;
      }),
      catchError((e) => {
        this.ui.disableLoader();
        console.log(e);
        return EMPTY;
      })
    );
  }
  //#endregion

  //#region REGISTRY LIST
  private readonly registryListQuery = gql`
    query RegistryValues($parentId: UUID!, $standardId: UUID) {
      registryValues(parentId: $parentId, standardId: $standardId) {
        registryName
        values {
          id
          name
          description
        }
      }
    }
  `;

  public getRegistryLsit(data: {
    parentId: string;
    standardId?: string | null;
    cacheKey?: string;
    useCache?: boolean;
  }) {
    let {
      parentId,
      standardId = null,
      cacheKey = parentId,
      useCache = true,
    } = data;

    if (!this.registryListRefs.has(cacheKey)) {
      this.ui.enableLoader();
      console.log('Main subscriber created ref for key ' + standardId);
      this.registryListRefs.set(
        cacheKey,
        this.apollo.watchQuery<FormControl, any>({
          query: this.registryListQuery,
          variables: { parentId, standardId },
          fetchPolicy: useCache ? 'cache-first' : 'network-only',
        })
      );
    }
    return this.registryListRefs.get(cacheKey)!.valueChanges.pipe(
      map<any, any>((result: any) => {
        this.ui.disableLoader();
        if (!result || !result.data) return null;

        const keys = Object.keys(result.data);
        if (result.data && keys.length) {
          return result.data[keys[0]];
        }
        return null;
      }),
      catchError((e) => {
        console.log(e);
        return EMPTY;
      })
    );
  }
  //#endregion

  //#region UPDATE VALUE

  private readonly editRegistryValueMutation = gql`
    mutation EditRegistryValue($request: RegistryChangeRequestInput!) {
      editRegistryValue(request: $request) {
        ...RegistryValueFormConfigFragment
      }
    }
    ${this.registryValueFormConfigFragment}
  `;

  public modifyReistryValue(
    data: RegistryChangeRequestInput
  ): Observable<FormControl> {
    this.ui.enableLoader();
    return this.apollo
      .mutate({
        mutation: this.editRegistryValueMutation,
        //!IMPORTANT
        // TODO  standard id is hardcoded
        refetchQueries: [
          {
            query: this.registryListQuery,
            variables: { parentId: data.parentId, standardId: null },
          },
        ],
        variables: { request: data },
      })
      .pipe(
        first(),
        map((result: any) => {
          if (!result || !result.data) return null;

          const keys = Object.keys(result.data);
          if (result.data && keys.length) {
            return result.data[keys[0]];
          }
          return null;
        }),
        tap(() => {
          this.ui.snack('ItemModified');
          this.ui.disableLoader();
        }),
        catchError((e) => {
          this.ui.snack(e.message);
          this.ui.disableLoader();
          return EMPTY;
        })
      );
  }
  //#endregion

  //#region CREATE VALUE
  private readonly createREgistryValueMutation = gql`
    mutation addRegistryValue($request: RegistryChangeRequestInput!) {
      addRegistryValue(request: $request) {
        ...RegistryValueFormConfigFragment
      }
    }
    ${this.registryValueFormConfigFragment}
  `;

  public createRegistryValue(data: RegistryChangeRequestInput) {
    this.ui.enableLoader();
    return this.apollo
      .mutate({
        mutation: this.createREgistryValueMutation,
        refetchQueries: [
          //!IMPORTANT
          // TODO  standard id is hardcoded
          {
            query: this.registryListQuery,
            variables: { parentId: data.parentId, standardId: null },
          },
        ],
        variables: { request: data },
      })
      .pipe(
        first(),
        map((result: any) => {
          if (!result || !result.data) return null;

          const keys = Object.keys(result.data);
          if (result.data && keys.length) {
            return result.data[keys[0]];
          }
          return null;
        }),
        tap(() => {
          this.ui.snack('ItemCreated');
          this.ui.disableLoader();
        }),
        catchError((e) => {
          this.ui.snack(e.message);
          this.ui.disableLoader();
          return EMPTY;
        })
      );
  }
  //#endregion

  //#region REMOVE FINAL VALUE
  private readonly removeFinalValueMutation = gql`
    mutation RemoveValue($command: RemoveValueCommandInput!) {
      removeValue(command: $command) {
        registryName
      }
    }
  `;

  public deleteFinalValue(valueId: string) {
    this.ui.enableLoader();
    return this.apollo
      .mutate({
        mutation: this.removeFinalValueMutation,
        variables: { command: { valueId } },
      })
      .pipe(
        first(),
        map((result: any) => {
          if (!result || !result.data) return null;

          const keys = Object.keys(result.data);
          if (result.data && keys.length) {
            return result.data[keys[0]];
          }

          return null;
        }),
        tap(() => {
          this.ui.snack('ItemDeleted');
          this.ui.disableLoader();
        }),
        catchError((e) => {
          this.ui.snack(e.message);
          this.ui.disableLoader();
          return EMPTY;
        })
      );
  }
  //#endregion

  refetchRegistryItem(rootId: string, valueId: string) {
    let cacheKey = rootId + valueId;
    this.formConfigRefs.get(cacheKey)?.refetch({ rootId, valueId });
  }

  refetchRegistryList(parentId: string) {
    this.registryListRefs.get(parentId)!.refetch({ parentId });
  }
}
