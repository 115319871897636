import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { StopClickPropagationDirective } from '@core/helpers';

@Component({
  selector: 'app-registers-menu',
  standalone: true,
  imports: [
    CommonModule,

    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,

    StopClickPropagationDirective,
  ],
  templateUrl: './registry-menu.component.html',
  styleUrls: ['./registry-menu.component.scss'],
})
export class RegistryMenuComponent {
  @Input()
  set items(value: any) {
    let arr = [...value];
    arr?.sort((a: any, b: any) =>
      a?.label < b?.label ? -1 : a?.label > b?.label ? 1 : 0
    );
    this.children = arr;
  }

  @Input() mode: 'menu' | 'select' = 'menu';
  @ViewChild('childMenu', { static: true }) public childMenu: any;
  @Output() onConfig: EventEmitter<any> = new EventEmitter();
  @Output() selectedPath: EventEmitter<any> = new EventEmitter();

  children: any[] = [];

  selectConfig(children: any) {
    this.selectedPath.emit(children.label);
    this.onConfig.emit(children);
  }

  // bubble Config:any;
  getConfig($event: any) {
    this.onConfig.emit($event);
  }

  buildPath(event: any) {
    this.selectedPath.emit(event);
  }
}
