import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { loginGuard } from './core/guards/login.guard';
import { WrapperComponent } from '@wrappers';
import { CustomTitleStrategy } from '@core/helpers';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./register/login/login.component').then((c) => c.LoginComponent),
    title: 'EE RS',
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./register/register/register.component').then(
        (c) => c.RegisterComponent
      ),
    title: 'Registracija',
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./register/forgot-password/forgot-password.component').then(
        (c) => c.ForgotPasswordComponent
      ),
  },
  {
    path: 'reset-password/:email/:code',
    loadComponent: () =>
      import('./register/reset-password/reset-password.component').then(
        (c) => c.ResetPasswordComponent
      ),
  },
  {
    path: 'verify/:email/:token',
    loadComponent: () =>
      import('./register/verify-email/verify-email.component').then(
        (c) => c.VerifyEmailComponent
      ),
    title: 'Verifikacija mejla',
  },
  {
    path: 'accept/:email/:token',
    loadComponent: () =>
      import('./register/accept-invitation/accept-invitation.component').then(
        (c) => c.AcceptInvitationComponent
      ),
    title: 'Pozivnica',
  },
  {
    path: '',
    component: WrapperComponent,
    canActivate: [loginGuard],
    children: [
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings-routing.module').then(
            (m) => m.SettingsRoutingModule
          ),
      },
      {
        path: 'registries',
        loadChildren: () =>
          import('./features/registries/registries-routing.module').then(
            (m) => m.RegistersRoutingModule
          ),
      },
      {
        path: 'features',
        loadChildren: () =>
          import('./features/feature-routing.module').then(
            (m) => m.FeatureRoutingModule
          ),
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
