import { Component, inject } from '@angular/core';
import { TranslationsService } from './settings/translations/translations.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private transService = inject(TranslationsService);
  constructor() {
    this.transService.getTranslations();
  }
}
