<h2 mat-dialog-title>{{ templateData.title + "" | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <p *ngIf="templateData.description" class="bdd-pt-5">
    {{ templateData.description | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [mat-dialog-close]="false">
    {{ templateData.denyAction + "" | translate }}
  </button>
  <button mat-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>
    {{ templateData.confirmAction + "" | translate }}
  </button>
</mat-dialog-actions>
