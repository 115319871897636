<div class="overlay" stop-click-propagation>
  @if (sidebarIsVisible) {
  <div
    class="main-sidebar"
    [@slideInOut]
    (@slideInOut.done)="animationStateChange($event)"
  >
    <mat-toolbar class="header">
      <mat-toolbar-row>
        <button mat-icon-button class="bdd-mr-1" (click)="closeSidebar()">
          <mat-icon>close</mat-icon>
        </button>
        <ng-content select="[header]"></ng-content>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="content-container">
      <ng-content></ng-content>
    </div>
    <div class="spacer"></div>
    <mat-toolbar class="footer">
      <mat-toolbar-row>
        <ng-content select="[footer]"></ng-content>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
  }
</div>
