import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService, UiService } from '@core/services';

export const loginGuard: CanActivateFn = () => {
  let auth = inject(AuthService);
  let ui = inject(UiService);
  let router = inject(Router);

  if (auth.user() && auth.authToken()) return true;

  ui.snack(
    'Neovlašten pristup sistemu je detektovan. Da biste nastavili neophodno je da se prijavite se na sistem!',
    'Uredu'
  );
  router.navigate(['/']);
  return false;
};
