import { Injectable } from '@angular/core';
import {
  ACCEPT_INVITE_USER,
  CURRENT_USER,
  INVITE_USER_MUTATION,
  LOCK_USER,
  REGISTER,
  REQUEST_RESET_PASSWORD,
  RESEND_INVITE_USER,
  RESEND_VERIFICATION_END_USER,
  RESET_PASSWORD,
  VERIFY_EMAIL,
} from '@core/graphql/core-fragments';
import {
  InviteAcceptChangeRequestInput,
  InviteChangeRequestInput,
  InviteResendChangeRequestInput,
  RegisterRequestInput,
  ResetPasswordChangeRequestInput,
  ResetPasswordRequestChangeRequestInput,
  User,
} from '@gqlSchema';
import { gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { EMPTY, Observable, catchError, finalize, map } from 'rxjs';
import { BaseService } from './base.service';

export type ResetPasswordInput = ResetPasswordRequestChangeRequestInput;

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService<User> {
  override readonly selectOneFields: DocumentNode = gql`
    fragment SelectOneFieldsUser on User {
      id
      jmb
      licenceNumber
      created
      email
      firstName
      lastName
      roles
      companies {
        id
        name
        jibNumber
      }
    }
  `;

  override readonly selectAllFields: DocumentNode = gql`
    fragment SelectAllFieldsUser on User {
      id
      emailConfirmed
      email
      firstName
      lastName
      created
      modified
      deleted
      roles
      isLocked
    }
  `;

  constructor() {
    super();
    this.advanceFilters = true;
    this.initGql('user');
  }

  public currentUser(): Observable<User> {
    return this.query({ query: CURRENT_USER, slot: 'currentUser' });
  }

  public resendVerificationEmail(): Observable<User> {
    return this.mutation(RESEND_VERIFICATION_END_USER, {});
  }

  public verifyUser(request: {
    email: string;
    verificationCode: string;
  }): Observable<User> {
    return this.mutation(VERIFY_EMAIL, { request });
  }

  public lockUser(data: { userId: string; doLock: boolean }): Observable<User> {
    return this.mutation(LOCK_USER, data);
  }

  public requestResetPassword(request: ResetPasswordInput) {
    return this.mutation(REQUEST_RESET_PASSWORD, { request });
  }

  public resetPassword(
    request: ResetPasswordChangeRequestInput
  ): Observable<User> {
    return this.mutation(RESET_PASSWORD, { request });
  }

  public register(request: RegisterRequestInput) {
    return this.mutation(REGISTER, { request });
  }

  public inviteUser(request: InviteChangeRequestInput): Observable<User> {
    return this.mutation(INVITE_USER_MUTATION, { request });
  }

  public acceptInvite(
    request: InviteAcceptChangeRequestInput
  ): Observable<User> {
    return this.mutation(ACCEPT_INVITE_USER, { request });
  }

  public resendInvite(
    request: InviteResendChangeRequestInput
  ): Observable<User> {
    return this.mutation(RESEND_INVITE_USER, { request });
  }

  public getAllUsers(): Observable<User[]> {
    this.setPaginationsToAll();
    if (this.allWQ.has('users')) {
      this.refetchAll();
    }

    return this.all().pipe(
      finalize(() => {
        this.resetPagination();
        this.fetchMoreData();
      }),
      catchError(() => {
        this.resetPagination();
        return EMPTY;
      })
    );
  }

  public getInvitationRoles(isBureau: boolean = true): Observable<string[]> {
    let slot = 'invitationRoles';
    if (this.queriesWQ.has(slot)) this.queriesWQ.delete(slot);

    return this.query({
      query: INVITATION_ROLES,
      slot: slot,
      data: { isBureau },
    }).pipe(map((e: any) => e.invitationRoles || []));
  }
}

export const INVITATION_ROLES: DocumentNode = gql`
  query InvitationRoles($isBureau: Boolean) {
    invitationRoles(isBureau: $isBureau)
  }
`;
