import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[stop-click-propagation]',
  standalone: true
})
export class StopClickPropagationDirective {

  @HostListener('click', ["$event"])
  public onClick(event: any): void {
    event.stopPropagation();
  }

}
