<div [ngClass]="{ wrapper: true, mini: mini }">
  <div class="container mat-elevation-z2">
    <div class="center">
      <a [routerLink]="['/']"
        ><img src="/assets/images/logo.png" class="logo"
      /></a>
    </div>
    @if (ui.loading) {
    <mat-spinner [diameter]="30"></mat-spinner>
    }
    <ng-content></ng-content>
  </div>
</div>
