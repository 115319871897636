export enum TokenRole {
  BasicUser = 'BasicUser',
  None = 'None',
  Auditor = 'Auditor',
  BureauAdmin = 'BureauAdmin',
  BureauManager = 'BureauManager',
  InstitutionAdmin = 'InstitutionAdmin',
  InstitutionManager = 'InstitutionManager',
  InstitutionUser = 'InstitutionUser',
  SuperAdmin = 'SuperAdmin',
}
