import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DialogTemplateData } from '@core/helpers/dialogs';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialo',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule, MatButtonModule],
  templateUrl: './confirmation-dialo.component.html',
  styleUrl: './confirmation-dialo.component.scss',
})
export class ConfirmationDialoComponent {
  dialogData = inject(MAT_DIALOG_DATA);
  templateData: DialogTemplateData;

  constructor() {
    this.templateData = this.dialogData;
  }
}
