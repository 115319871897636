import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseService } from 'src/app/core/services/base.service';
import { UiService } from '@core/services';
import { CustomPaginatorIntl } from '@core/helpers';
import { EmptyListPlaceholderComponent } from '@shared/components';
import { expandCollapse } from 'src/app/animations/expand-collapse.animations';

@Component({
  selector: 'app-list-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,

    EmptyListPlaceholderComponent,

    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatPaginatorModule,
  ],
  templateUrl: './list-wrapper.component.html',
  styleUrls: ['./list-wrapper.component.scss'],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl,
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: { formFieldAppearance: 'fill' },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse],
})
export class ListWrapperComponent implements OnInit {
  ui = inject(UiService);
  router = inject(Router);
  route = inject(ActivatedRoute);

  @Input({ required: true }) service!: BaseService;
  @Input({ required: true }) managePerm!: any;
  @Input('hideNewButton') hideNewButoon: boolean = false;
  @Input('showDeleted') showDeleted: boolean = true;
  @Input() set nagivate(value: string | string[]) {
    this._path = Array.isArray(value) ? [...value] : [value];
  }

  _showDeleted!: boolean;
  _paginationSize: number[] = [5, 10, 25, 50, 100];

  private _path!: string[];

  ngOnInit(): void {
    // this._showDeleted = this.service.queryParams.showDeleted;
  }

  public addNewItem() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  public getDeletedItems() {
    this.service.pageIndex = 0;
    this.service.queryParams.offset = 0;
    this._showDeleted = !this._showDeleted;
    this.service.showDeleted(this._showDeleted);
  }

  public editItem(id: number | string | null | undefined) {
    this.router.navigate([id], { relativeTo: this.route });
  }
}
