import { InjectionToken } from "@angular/core"

export const ERROR_MESSAGES: { [key: string]: (args?: any) => string } = {
   required: () => `Polje je obavezno`,
   requiredtrue: () => `Polje je obavezno`,
   email: () => `Format mejla nije validan`,
   minlength: ({ requiredLength }) => `Minimalna duzina je ${requiredLength}`,
   maxlength: ({ requiredLength }) => `Maksimalna duzina je ${requiredLength}`,
   min: ({ min }) => `Minimalna vrijednost je ${min}`,
   max: ({ max }) => `Maksimalnda vrijednost je ${max}`,
   pattern: () => `Vrijednost ne zadovoljava format`
}

export const VALIDATION_ERROR_MESSAGES = new InjectionToken('Validation Messages', {
   providedIn: "root",
   factory: () => ERROR_MESSAGES
})